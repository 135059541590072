import Api from '../Api'
import ModelFile from '../ModelFile'
import { buildFormData } from './Common'
import Model from './Model'

export default class ModelWithFiles extends Model {
  public model_files_meta: Array<any> = []

  public model_files_binary: Array<File> = []

  public files: ModelFile[] = []

  public addFile(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.model_files_binary.push(files[i])
    }
  }

  public removeFile(id: string) {
    this.model_files_meta.push({
      id,
      delete: true,
    })
    // remove file from array
    this.files = this.files.filter(file => file.id !== id)
  }

  public save() {
    if (!this.api_settings.paths.singular) {
      return Promise.reject(new Error('Invalid API settings'))
    }

    if (!this.model_files_binary || this.model_files_binary.length === 0) {
      return this.saveModel()
    }

    return this.preUploadFiles().then(response => {
      const files = response.data.result.files

      this.model_files_meta = this.model_files_meta.map((meta: any) => {
        for (let key in files) {
          if (meta.source_name === key) {
            meta.tmp_source = files[key]
            break
          }
        }
        return meta
      })
      return this.saveModel()
    })
  }

  private saveModel() {
    if (!this.api_settings.paths.singular) {
      return Promise.reject(new Error('Invalid API settings'))
    }
    const api = new Api()
    if (this.api_settings.save_mode === 'form') {
      return api
        .post(
          this.id
            ? `${this.api_settings.paths.singular}/${this.id}`
            : this.api_settings.paths.singular,
          { ...this.apiData, model_files_meta: this.model_files_meta },
        )
        .then(response =>
          // @ts-ignore
          this.onSave(response, { target: this.api_settings.paths.singular, name: this.name }))
        .catch(this.onError)
    }
    return api
      .post(
        this.id
          ? `${this.api_settings.paths.singular}/${this.id}`
          : this.api_settings.paths.singular,
        { ...this.apiData, model_files_meta: this.model_files_meta },
      )
      .then(response =>
        // @ts-ignore
        this.onSave(response, { target: this.api_settings.paths.singular, name: this.name }))
      .catch(this.onError)
  }

  public preUploadFiles() {
    const formData = new FormData()
    buildFormData(formData, {
      model_files_meta: this.model_files_meta,
      model_files_binary: this.model_files_binary,
    })

    const api = new Api()
    return api.form('model_file/pre-upload', formData).catch(this.onError)
  }
}
